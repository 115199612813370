// @file Helper methods for the user model

import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import type { User, UserCamelCase } from '@@/types'

export const ROLE_MAP = {
  owner: __('owner'),
  admin: __('admin'),
  teacher: __('teacher'),
  student: __('student'),
  member: __('member'),
}

export enum UserRole {
  OWNER = 'owner',
  TEACHER = 'teacher',
  ADMIN = 'admin',
  STUDENT = 'student',
  MEMBER = 'member',
  DEFAULT = '',
}

export const anonymous = __('Anonymous')
export const ANONYMOUS_AVATAR = 'https://padlet.net/avatars/alien1.png' // copied from library/v4/components/OzAvatar.vue
export const blankUser = (): User => {
  return {
    id: -1,
    avatar: ANONYMOUS_AVATAR,
    tenant_id: 1,
    tenant_type: 'native',
    paying: false,
    quota: {
      walls_used: 0,
      walls_limit: 0,
      advertized_walls_limit: 0,
      can_make: false,
    },
    role: UserRole.STUDENT,
    lang: 'en',
    created_at: new Date(),
    registered_at: new Date(),
    name: anonymous,
    email: undefined,
    short_name: undefined,
    registered: false,
    profile_url: undefined,
    days_before_freeze: 0,
    avatar_dominant_rgb: [255, 255, 255],
    is_archive_enabled: !isAppUsing('removeArchive'),
  }
}

export function translatedRole(role: UserRole): string {
  return ROLE_MAP[role]
}

export const displayNameForUser = (user: User): string => {
  return user?.name || user?.username || ''
}

export const isAdmin = (user: User): boolean => [UserRole.OWNER, UserRole.ADMIN, UserRole.TEACHER].includes(user.role)

export const isOwner = (user: User | UserCamelCase): boolean => user.role === UserRole.OWNER

export const isRegistered = (user: Partial<Pick<User, 'registered_at' | 'registered'>>): boolean =>
  user.registered_at != null || Boolean(user.registered)
